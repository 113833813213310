<template>
  <div class="relative flex flex-col min-h-screen antialiased">
    <header class="fixed w-full">
      <div class="container lg:px-10 max-w-7xl">
        <div class="py-4">
          <div class="text-center">
            <img :src="logo" alt="logo" class="object-contain object-left w-40 h-8" width="160" height="32" />
          </div>
        </div>
      </div>
    </header>

    <main
      id="sb-1"
      class="flex items-center min-h-screen bg-center bg-no-repeat bg-cover min-w-screen justify-items-center sb-1"
      :style="{
        'background-color': '#292935',
        'background-image': `url(${require('@/assets/images/pipeline.webp')})`,
      }"
    >
      <div class="mx-auto text-center">
        <div>
          <h1
            id="sb-1-header"
            class="
              relative
              inline-block
              px-4
              py-2
              mb-8
              overflow-hidden
              text-6xl
              font-bold
              text-white
              bg-black
              rounded rounded-lg
              header-bg
              bg-opacity-40
            "
          >
            <p>SIGN UP TODAY</p>
          </h1>
        </div>
        <div>
          <h2
            id="sb-1-subheader"
            class="
              relative
              z-10
              inline-block
              px-3
              py-2
              mb-8
              overflow-hidden
              text-2xl
              font-bold
              text-white
              rounded rounded-lg
              header-bg
              sm:text-3xl
              bg-opacity-40
            "
          >
            Start Investing with our Award Winning FREE Online Investment Platform
          </h2>
        </div>
        <div>
          <button
            class="
              inline-block
              px-10
              py-6
              text-2xl
              font-bold
              transform
              bg-yellow-400
              rounded-lg
              sm:py-7 sm:px-12 sm:text-3xl
              grow-shrink
            "
            @click="openRegisterModal"
          >
            START NOW
          </button>
        </div>
        <div></div>
      </div>
    </main>

    <register-form modal ref="register-form"></register-form>
  </div>
</template>

<script>
import RegisterForm from '@/view/components/register-form'

export default {
  components: {
    RegisterForm,
  },
  data: () => ({
    logo: process.env.VUE_APP_LOGO || '/images/logo.png',
  }),
  methods: {
    openRegisterModal() {
      const registerForm = this.$refs['register-form'];

      if (registerForm) {
        registerForm.openModal();
      }
    }
  }
}
</script>
