<template>
  <section
    class="
      fixed
      top-0
      left-0
      z-50
      flex
      items-center
      justify-center
      w-full
      h-full
      bg-black
      exit-intent
      lg:py-4
      bg-opacity-80
    "
    v-show="show"
  >
    <div
      class="
        relative
        flex flex-col
        max-w-full max-h-full
        p-2
        bg-cover
        border-4 border-red-500
        rounded
        overflow-scroll
        remove-scrollbar
        lg:p-0 lg:flex-row
        md:w-10/12
        lg:w-3/4
      "
      :style="{
        'background-image': `url(${require('@/assets/images/background.webp')})`,
      }"
    >

      <button
        class="absolute w-4 h-4 p-1 text-white border border-white rounded-full lg:inline-block right-3 top-3"
        v-if="modal"
        @click="open = false"
      >
        <svg class="w-full h-full" viewBox="0 0 365.696 365.696">
          <path
            fill="currentColor"
            d="M243.188 182.86L356.32 69.726c12.5-12.5 12.5-32.766 0-45.247L341.238 9.398c-12.504-12.503-32.77-12.503-45.25 0L182.86 122.528 69.727 9.374c-12.5-12.5-32.766-12.5-45.247 0L9.375 24.457c-12.5 12.504-12.5 32.77 0 45.25l113.152 113.152L9.398 295.99c-12.503 12.503-12.503 32.769 0 45.25L24.48 356.32c12.5 12.5 32.766 12.5 45.247 0l113.132-113.132L295.99 356.32c12.503 12.5 32.769 12.5 45.25 0l15.081-15.082c12.5-12.504 12.5-32.77 0-45.25zm0 0"
          ></path>
        </svg>
      </button>
      <div class="flex flex-col justify-between flex-1 flex-shrink-0 lg:w-6/12 lg:pt-10 lg:px-14 lg:-mr-14">
        <div>
          <h2 class="mb-6 text-4xl font-bold text-yellow-500 lg:text-5xl w-21/24 lg:w-auto">
            {{ $t('register.title') }}
          </h2>
          <div class="mb-6 text-sm font-bold prose-sm prose text-white lg:text-lg max-w-none">
            {{ $t('register.description') }}
          </div>
        </div>
        <div class="inline-block w-80">
          <img class="hidden w-full h-auto lg:block w-80" src="@/assets/images/app.png" />
        </div>
      </div>

      <div class="h-full lg:w-6/12 lg:py-10 lg:px-14">
        <div class="border-2 border-white rounded bg-darkBlue">
          <div class="px-4 pt-4 text-3xl font-bold text-center text-white">{{ $t('register.registerNow') }}</div>
          <div id="registration-exit-intent">
            <main data-version="2.7.9" class="antialiased font-sans">
              <section class="p-4">
                <form @submit.prevent="onSubmit">
                  <fieldset>
                    <label class="flex w-full mb-4" underage-redirect-link=""
                      ><div class="w-full relative">
                        <input
                          :placeholder="$t('register.fullName')"
                          pattern=".+?(?:[\s'].+?){1,}"
                          type="text"
                          name="fullName"
                          v-model="name"
                          required="required"
                          minlength="2"
                          maxlength="30"
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-900
                            font-medium
                            border border-gray-400
                            rounded-lg
                            py-3
                            px-4
                            leading-tight
                            focus:outline-none
                            shadow-none
                          "
                        /></div
                    ></label>
                  </fieldset>

                  <fieldset>
                    <label class="flex w-full mb-4" underage-redirect-link="">
                      <div class="w-full relative">
                        <input
                          :placeholder="$t('register.email')"
                          type="email"
                          name="email"
                          v-model="email"
                          required="required"
                          minlength="5"
                          pattern="[A-Za-z0-9._%+-]{1,}@[a-zA-Z0-9._%+-]{1,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z0-9._%+-]{2,}[.]{1}[a-zA-Z]{2,})"
                          class="
                            appearance-none
                            block
                            w-full
                            bg-white
                            text-gray-900
                            font-medium
                            border border-gray-400
                            rounded-lg
                            py-3
                            px-4
                            leading-tight
                            focus:outline-none
                            shadow-none
                          "
                        /></div
                    ></label>
                  </fieldset>

                  <fieldset>
                    <input
                      type="tel"
                      class="
                        appearance-none
                        block
                        w-full
                        bg-white
                        text-gray-900
                        font-medium
                        border border-gray-400
                        rounded-lg
                        py-3
                        px-4
                        leading-tight
                        focus:outline-none
                        shadow-none
                      "
                      maxlength="10"
                      name=""
                      placeholder=""
                      pattern="\d*"
                      id="telephone"
                      aria-label="Your phone number"
                      required="required"
                    />
                  </fieldset>

                  <fieldset>
                    <span style="color: #fff;">{{ $t('register.howOldAreU') }}</span>
                    <select
                      id="age" name="age"
                      v-model="age"
                      class="
                        appearance-none
                        block
                        w-full
                        bg-white
                        text-gray-900
                        font-medium
                        border border-gray-400
                        rounded-lg
                        py-3
                        px-4
                        mb-4
                        leading-tight
                        focus:outline-none
                        shadow-none
                      "
                      aria-label="Your age" required="required" placeholder=""
                    >
                      <option value="-18">18</option>
                      <option value="18-25">18-25</option>
                      <option value="25-35">25-35</option>
                      <option value="35+">35+</option>
                    </select>
                  </fieldset>

                  <div>
                    <button
                      :disabled="disabled"
                      class="
                        block
                        w-full
                        px-4
                        py-4
                        text-center text-white
                        rounded-lg
                        bg-blazeorange
                        hover:bg-orange-500
                        active:bg-orange-700
                        focus:outline-none
                      "
                      type="submit"
                    >
                      <div class="flex items-center justify-center font-bold">
                        <span class="mr-4 text-xl">{{ disabled ? $t('register.loading') : $t('register.startTradingNow') }}</span>
                        <div class="w-4 text-white transform rotate-90" v-if="!disabled">
                          <svg viewBox="0,0,97,97" class="w-full h-full">
                            <path
                              fill="currentColor"
                              d="M83.952 34.479L50.056.585a2 2 0 00-2.828 0L13.333 34.479a2 2 0 000 2.828l9.03 9.031a1.999 1.999 0 002.828 0l14.534-14.532v63.478a2 2 0 002 2H55.56a2 2 0 002-2V31.807l14.532 14.532c.75.75 2.078.75 2.828 0l9.031-9.031a2 2 0 00.001-2.829z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </button>
                  </div>
                </form>
              </section>
            </main>
          </div>
          <p class="px-4 pb-4 text-xs text-center text-error" v-if="error">
            {{ error }}
          </p>
          <p class="px-4 pb-4 text-xs text-center text-warmGray-800 form-disclaimer">
            {{ $t('register.terms') }}
          </p>
        </div>
        <div class="flex items-start py-3"><div class="flex-1"></div></div>
        <div class="flex justify-between w-full">
          <div class="flex h-8">
            <img
              class="h-full mr-3"
              src="@/assets/images/1628256866885-06c04f76-0a17-4690-9be4-372095720bf2.png"
              alt="Secure"
            />
            <img class="h-12 h-auto" src="@/assets/images/1628256481776-af5565fc-a44a-4df8-b621-45873a69fb76.png" />
          </div>
          <img :src="logo" class="object-contain object-right h-12" width="160" height="32" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import intlTelInput from 'intl-tel-input';

export default {
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    pixelURI: 'https://pixelapp-hkkkbqjv2a-uc.a.run.app',
    logo: process.env.VUE_APP_LOGO || '/images/logo.png',
    open: false,
    disabled: false,
    pushLeadLoading: false,
    phone: null,
    name: '',
    email: '',
    age: '-18',
    error: ''
  }),
  mounted() {
    const phone = document.querySelector('#telephone')

    this.phone = intlTelInput(phone, {
      initialCountry: 'auto',
      separateDialCode: true,
      hiddenInput: 'phone',
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js', // just for formatting/placeholders etc
      geoIpLookup: function (success, failure) {
        $.get('https://ipinfo.io', function () {}, 'jsonp').always(function (resp) {
          const countryCode = resp && resp.country ? resp.country : 'us'
          success(countryCode)
        })
      },
    });

    window.addEventListener("message", (event) => {
      if (event.origin !== this.pixelURI) return;

      if (event.data) {
        this.showLeadResult(event.data);
      }
    }, false);
  },
  computed: {
    show() {
      return (this.modal && this.open) || !this.modal
    }
  },
  methods: {
    openModal() {
      this.open = true
    },

    onSubmit() {
      this.error = '';
      this.disabled = true;

      if (this.age === "-18") {
        this.disabled = false;

        return this.error = 'You need to be at least 18 years old';
      }

      const phone = this.phone.getNumber();
      const name = this.name;
      const email = this.email;

      fetch(`https://validate-hkkkbqjv2a-uc.a.run.app/validate?phone=${phone}&name=${name}&email=${email}&language=en`, {
        method: "GET",
      })
      .then((response) => response.json())
      .then((res) => {
          if (res.valid) {
            const params = new URLSearchParams({
              ...Object.fromEntries(new URLSearchParams(window.location.search)),
              lang: this.$i18n.locale,
              age: this.age,
              phone, name, email,
              step: "lander",
            });

            this.$gtm.trackEvent({
              event: 'lead'
            });

            this.pushLeadLoading = true;
            this.pushLead(params.toString());
          }

          ['phone', 'name', 'email'].forEach(field => {
            if (!res[field] && !this.error) {
              this.error = res.reason[field];
            }
          })

          if (!this.pushLeadLoading) {
            this.disabled = false;
          }
      })
    },

    pushLead(params) {
      document.querySelectorAll('.pixel-iframe').forEach(e => e.remove());

      const ifrm = document.createElement("iframe")

      ifrm.setAttribute("src", `${this.pixelURI}?${params}`);
      ifrm.classList.add('pixel-iframe')
      ifrm.setAttribute("allow-same-origin", true)
      ifrm.style.width = "0px";
      ifrm.style.height = "0px";

      document.body.appendChild(ifrm);
    },

    showLeadResult(data) {
      if (data.valid && data.url) {
        window.location.replace(data.url)
      } else {
        this.error = data.reason || 'Unknown error';
      }

      this.pushLeadLoading = false;
      this.disabled = false;
    }
  },
}
</script>
